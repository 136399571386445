// 千位分隔符
export const scientific = (fee) => {
  let source = fee.toString().split(".");
  source[0] = source[0].replace(
    new RegExp("(\\d)(?=(\\d{3})+$)", "ig"),
    "$1,"
  );
  return fee ? source.join(".") : 0;
};

export const toLegalNum = (num) => {
  const legalNum = num && !isNaN(Number(num)) ? Number(num).toFixed(2) : 0;
  return Number(legalNum);
}

export const toFixed2 = (num) => {
  const result = Number(Number(num).toFixed(2));
  return isNaN(result) ? 0 : result;
}
export const toFixed4 = (num) => {
  const result = Number(Number(num).toFixed(4));
  return isNaN(result) ? 0 : result;
}