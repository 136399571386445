import request from "@/utils/request";


// 获取论坛列表
export function listForum(params) {
  return request({
    url: "/forum/list",
    method: "get",
    params
  });
}


// 论坛添加
export function forumAdd(data, method = 'post') {
  return request({
    url: "/forum",
    method,
    data
  });
}


// 论坛评论
export function forumComment(data) {
  return request({
    url: "/forum/comment",
    method: "post",
    data
  });
}


// 获取论坛详情
export function detailForum(id) {
  return request({
    url: '/forum/' + id,
    method: "get",
  });
}

// 帖子点赞
export function forumLike(id) {
  return request({
    url: '/forum/like/' + id,
    method: "post",
  });
}

// 帖子取消点赞
export function forumUnlike(id) {
  return request({
    url: '/forum/unlike/' + id,
    method: "post",
  });
}
// 帖子点赞
export function forumCommentLike(id) {
  return request({
    url: '/forum/comment/like/' + id,
    method: "post",
  });
}

// 帖子取消点赞
export function forumCommentUnlike(id) {
  return request({
    url: '/forum/comment/unlike/' + id,
    method: "post",
  });
}



// 获取我点赞的论坛列表
export function listForumLike(params) {
  return request({
    url: "/forum/like/mine",
    method: "post",
    params
  });
}


