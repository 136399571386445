<template>
  <div id="app">
    <router-view v-if="isRouterShow" />
  </div>
  <!--  -->
</template>

<script>
export default {
  data() {
      return {
          isRouterShow: true
      }
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  async reload () {
    this.isRouterShow = false
    await this.$nextTick()
    this.isRouterShow = true
  },
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding-top: 70px!important;
  background-color: #121212;
  padding-right: 0!important;
}

html {
  height: 100%;
}

#app, textarea {
  font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}
button {
  cursor: pointer;
  outline: none;
  border: none;
}

a {
  text-decoration: none;
}
input {
  outline: none;
  border: none;
}

.vxe-table {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #eff0f5;
    border-radius: 3px;
  }
  .vxe-table--body-wrapper {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .vxe-table--header {
    background-color: #fff;
    color: #111111;
  }
  .headerCell {
    background-color: #e4d5ca;
    &:first-child {
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-bottom-right-radius: 10px;
    }
  }
  .vxe-table--header-border-line {
    border-bottom-color: #fff !important;
  }
  
  .vxe-table--border-line {
    border-bottom-color: #fff !important;
  }
  .vxe-body--row {
    color: #111111;
  }
  
}


.content {
  word-break: break-all;
}

.container {
  width: 980px;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .container {
    width: 900px;
  }
}
@media screen and (min-width: 1100px) {
  .container {
    width: 1000px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    width: 1100px;
  }
}
@media screen and (min-width: 1280px) {
  .container {
    width: 1150px;
  }
}
@media screen and (min-width: 1366px) {
  .container {
    width: 1200px;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    width: 1300px;
  }
}
@media screen and (min-width: 1680px) {
  .container {
    width: 1460px;
  }
}

.el-message {
  z-index: 9999!important;
}
.pagination-container {
  & .el-pagination {
    text-align: center;
    .btn-prev, .btn-quickprev, .btn-quicknext, .btn-next, .number {
      border-radius: 10px!important;
      background-color: #F5E7DC!important;
      color: #45443B!important;
    }
    .number.active {
      background-color: #ED7010!important;
      color: #FFFFFF!important;
    }
    .number:not(.active):hover {
      color: #ED7010!important;
    }
  }
}
ul:not(.el-scrollbar__view) {
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    list-style: none;
  }
}
.background-public {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.search-inner::placeholder {
  color: #bfbfbf;
}


.search-area {
  .el-tag {
    background-color: #EADCD0;
    border-color: #EADCD0;
    color: #8A7B6F;
    border-radius: 10px;
    margin-left: 5px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 20px;
    .el-icon-close {
      transform: scale(1);
      margin-left: 3px;
      color: #8A7B6F;
      &:hover {
        color: #fff;
        background-color: #8A7B6F;
      }
    }
  }
}
.video-js {
  height: 100%!important;
}
.vjs-fluid {
  padding-top: 0!important;
}


.active-item {
  box-sizing: border-box;
  border: 2px solid #fc6804;
}


.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
  
  .circle-side {
    position: absolute;
    width: 25px;
    height: 25px;
    top: calc(50% - 12.5px);
    left: calc(50% - 12.5px);
    -webkit-animation: circle infinite 0.75s linear;
    -moz-animation: circle infinite 0.75s linear;
    -o-animation: circle infinite 0.75s linear;
    animation: circle infinite 0.75s linear;
    border: 2px solid #fff;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-right-color: rgba(0, 0, 0, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-radius: 100%;
  }
}

@keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.cover-image {
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.vxe-button.type--text:not(.is--disabled, .agent-text-button) {
  color: #fc6804!important;
}
.tooltip-none {
  .el-tooltip__popper{
    border: none !important;;
    padding: 0!important;
    overflow: hidden;
    border-radius: 10px;
  }
}

.drop-menu {
  z-index: 3335!important;
}
.tool-popper {
  z-index: 3334!important;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
.select-popper {
  font-weight: bold;
}
.viewer-button {
  top: 55px!important;
  right: 0px!important;
}

.viewer-title {
  display: none!important;
}
.viewer-toolbar {
  display: none!important;
}
.viewer-active {
  display: none!important;;
}


.projector-sel {
  text-align: center;
  background-color: #F6E7D5!important;
  color: #141414!important;
  margin-top: 1px!important;
  border: none!important;
  border-radius: 15px!important;
  overflow: hidden;
  box-shadow: 0px 7px 14px 0px rgba(52,37,19,0.4);
  .el-select-dropdown__list {
    padding: 0!important;
  }
  .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background-color: #F6E7D5;
  }
  .el-select-dropdown__item {
    background-color: #E9D7C1;
  }
  .el-select-dropdown__item.selected {
    font-weight: bold;
    color: #141414!important;
    background-color: #F6E7D5;
  }
  
  .popper__arrow {
    display: none!important;
  }
}

#nprogress .bar {
  z-index: 10000!important;
  background: #fc6804!important;
}
</style>
