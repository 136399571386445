import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/theme/index.css'
import './assets/theme/font.css'
import "@/assets/iconfonts/iconfont/iconfont.css";
import "@/assets/iconfonts/sdIconfont/iconfont.css";
import "@/assets/iconfonts/calIconfont/iconfont.css";


import './assets/js/resize'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import SvgIcon from '@/components/SvgIcon'
import { Message } from 'element-ui';
import directive from './directive'
import VueClipboard from 'vue-clipboard2'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';


// 图片加载器
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

// vue-slider
Vue.use(VueAwesomeSwiper);

// 关闭el-dialog弹出时带来的页面样式变化问题
Element.Dialog.props.lockScroll.default = false

// 表格组件
Vue.use(VXETable)
Vue.use(Element, {
  size: 'mini' // set element-ui default size
})


Vue.use(directive)



Vue.config.productionTip = false

// 全局默认图片
Vue.prototype.$defaultUrl = 'http://120.48.178.60:9000/goldon-cloud-icon/other.jpg'


// 元素尺寸变化检测组件
import VueResize from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
Vue.use(VueResize)



// video-player
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
//在main.js内
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer);
Vue.component('svg-icon', SvgIcon)


// 全局角色获取
Vue.prototype.$getDownLoadRole = () => {
  const super_admin = "admin";
  const roles = store.getters && store.getters.roles

  const value = ['leader', 'approval&manager', 'saadmin']

  if (value && value instanceof Array && value.length > 0) {
    const roleFlag = value

    const hasRoleClick = roles.some(role => {
      return super_admin === role || roleFlag.includes(role)
    })
    if (!hasRoleClick) {
      return false
    } else {
      return true
    }
  } else {
    throw new Error(`请设置角色权限标签值"`)
  }
}

// 高亮插件
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/base16/brewer.css'
Vue.use(VueHighlightJS)


// 日期格式化插件
import moment from 'moment'
Vue.prototype.$moment = moment;

// clipboard插件
Vue.use(VueClipboard)

// 修改message位置，重写message方法修改offset
const $message = options => {
  return Message({
    ...options,
    offset: 60
  });
};

//重写一遍success的方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 100
      };
    }
    options.type = type;
    return Message(options);
  };
});
//将$message挂载到this上
Vue.prototype.$message = $message;


// 图片懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.0,
  loading: require('./assets/images/mj/loading.gif')
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
