import request from "@/utils/request";

export function adminNotice() {
  return request({
    url: `/portal/notice/polling`,
    method: "get"
  })
}

export function adminNoticeList() {
  return request({
    url: `/portal/notice/list`,
    method: "get"
  })
}