import request from "../../utils/request";

// 是否阅读过更新
export function isReadVersion() {
  return request({
    url: '/system/version/isread',
    method: 'post',
  })
}

// 是否阅读过更新
export function getVersion(versionId) {
  return request({
    url: '/system/version/read/' + versionId,
    method: 'post',
  })
}