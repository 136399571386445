import Cookies from 'js-cookie'

const TokenKey = 'Portal-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

const domainName = process.env.NODE_ENV == 'development' ? 'localhost': 'jd-cg-cloud.com'

export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    expires: 1,
    domain: domainName,
    path: '/'
  })
}

export function removeToken() {
  return Cookies.remove(TokenKey, {
    expires: 1,
    domain: domainName,
    path: '/'
  })
}
