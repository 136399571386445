const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  userName: state => state.user.userName,
  nickName: state => state.user.nickName,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  userId: state => state.user.userId,
  sex: state => state.user.sex,
  adjustAvailable: state => state.calculator.adjustAvailable,
  autoSaveAvailable: state => state.calculator.autoSaveAvailable,
  skuStatisticMap: state => state.calculator.skuStatisticMap,
  showCost: state => state.calculator.showCost,
  showRate: state => state.calculator.showRate,
  globalHardSkuTotalPrice: state => state.calculator.globalHardSkuTotalPrice,
  globalHardSkuTotalCost: state => state.calculator.globalHardSkuTotalCost,
  globalHardSkuList: state => state.calculator.globalHardSkuList,
  globalSoftSkuList: state => state.calculator.globalSoftSkuList,
  globalSoftWorkList: state => state.calculator.globalSoftWorkList,
  globalFilmWorkList: state => state.calculator.globalFilmWorkList
}
export default getters
