import { reduceStatistics } from "@/utils/calculatorTool"
import { skuStatistics } from '@/api/calculator/calculator2'
import { set } from "vue"
import _ from 'lodash'


const skuStaticFunction = _.debounce((hardSkuStaticList, callback) => {
  skuStatistics(hardSkuStaticList).then(({ code, data }) => {
    if(code === 200) {
      callback(data)
    }
  })
}, 300)

const calculator = {
  state: {
    /**
     * 全局参数
     */
    adjustAvailable: true, // 是否启用调价
    autoSaveAvailable: true, // 是否启用自动保存
    skuStatisticMap: {}, // 全局统计项
    showCost: true, // 是否显示成本
    showRate: true, // 是否显示毛利率

    /**
     * 硬件相关
     */
    globalHardSkuList: [],
    globalHardSkuTotalPrice: 0,
    globalHardSkuTotalCost: 0,

    /**
     * 软件产品相关
     */
    globalSoftSkuList: [],

    /**
     * 软件程序相关
     */
    globalSoftWorkList: [],

    /**
     * 数字影片相关
     */
    globalFilmWorkList: []
  },
  mutations: {
    /**
     * 全局参数修改
     */
    SET_ADJUST_WATCHER_STATE: (state, isAvailable) => {
      state.adjustAvailable = isAvailable
    },
    SET_AUTO_SAVE_STATE: (state, isAvailable) => {
      state.autoSaveAvailable = isAvailable
    },
    SET_GLOBAL_SKU_STATISTICS: (state, map) => {
      state.skuStatisticMap = map
    },
    SET_SHOW_COST_STATE: (state, showCost) => {
      state.showCost = showCost
    },
    SET_SHOW_RATE_STATE: (state, showRate) => {
      state.showRate = showRate
    },

    /**
     * 硬件相关参数修改
     */
    SET_GLOBAL_HARD_SKU_LIST: (state, globalHardSkuList) => {
      state.globalHardSkuList = globalHardSkuList
    },
    SET_GLOBAL_HARD_SKU_TOTAL_PRICE: (state, globalHardSkuTotalPrice) => {
      state.globalHardSkuTotalPrice = globalHardSkuTotalPrice
    },
    SET_GLOBAL_HARD_SKU_TOTAL_COST: (state, globalHardSkuTotalCost) => {
      state.globalHardSkuTotalCost = globalHardSkuTotalCost
    },
    UPDATE_HARD_LOCK_STATES_AND_PRICE: (state, { skuId, price, priceLock }) => {
      state.globalHardSkuList.map(sku => {
        if (sku.skuId == skuId) {
          sku.price = price
          sku.priceLock = priceLock
        }
      })
    },

    /**
     * 软件相关参数修改
     */
    SET_GLOBAL_SOFT_SKU_LIST: (state, globalSoftSkuList) => {
      state.globalSoftSkuList = globalSoftSkuList
    },
    UPDATE_SOFT_LOCK_STATES_AND_PRICE: (state, { skuId, price, priceLock }) => {
      state.globalSoftSkuList.map(sku => {
        if (sku.skuId == skuId) {
          sku.price = price
          sku.priceLock = priceLock
        }
      })
    },


    /**
     * 软件产品相关
     */
    SET_GLOBAL_SOFT_WORK_LIST: (state, globalSoftWorkList) => {
      state.globalSoftWorkList = globalSoftWorkList
    },
    UPDATE_SOFT_PRODUCT_LOCK_STATES_AND_PRICE: (state, { specificationId, specificationOptionId, price, priceLock }) => {
      state.globalSoftWorkList.map(work => {
        if (work.specificationId == specificationId && work.specificationOptionId == specificationOptionId) {
          work.price = price
          work.priceLock = priceLock
        }
      })
    },


    /**
     * 数字影片相关
     */
    SET_GLOBAL_FILM_WORK_LIST: (state, globalFilmWorkList) => {
      state.globalFilmWorkList = globalFilmWorkList
    },

    UPDATE_DIGITAL_FILM_LOCK_STATES_AND_PRICE: (state, { skuId, price, priceLock }) => {
      state.globalFilmWorkList.map(sku => {
        if (sku.skuId == skuId) {
          sku.price = price
          sku.priceLock = priceLock
        }
      })
    },

    // UPDATE_DIGITAL_FILM_LOCK_STATES_AND_PRICE: (state, { specificationId, specificationOptionId, price, priceLock }) => {
    //   state.globalFilmWorkList.map(work => {
    //     if(work.specificationId == 0) return
    //     if(work.specificationId == specificationId && work.specificationOptionId == specificationOptionId) {
    //       work.price = price
    //       work.priceLock = priceLock
    //     }
    //   })
    // },

    // 品牌单品替换
    REPLACE_HARD_SKU: (state, { oldSku, newSku }) => {
      for (let i = 0; i < state.globalHardSkuList.length; i++) {
        const sku = state.globalHardSkuList[i]
        if (!sku.brandLock && sku.skuId == oldSku.skuId) {
          for (let key in sku) {
            if (key != 'num') {
              set(sku, key, newSku[key])
              // sku[key] = newSku[key]
            }
          }
        }
      }
    },
    REPLACE_SOFT_SKU: (state, { oldSku, newSku }) => {
      for (let i = 0; i < state.globalSoftSkuList.length; i++) {
        const sku = state.globalSoftSkuList[i]
        if (!sku.brandLock && sku.skuId == oldSku.skuId) {
          for (let key in sku) {
            if (key != 'num') {
              sku[key] = newSku[key]
            }
          }
        }
      }
    },
    REPLACE_FILM_SKU: (state, { oldSku, newSku }) => {
      for (let i = 0; i < state.globalFilmWorkList.length; i++) {
        const sku = state.globalFilmWorkList[i]
        if (!sku.brandLock && sku.skuId == oldSku.skuId) {
          for (let key in sku) {
            if (key != 'num') {
              sku[key] = newSku[key]
            }
          }
        }
      }
    },
  },
  actions: {
    /**
     * 全局方法
     */
    ChangeAdjustWatcherState({ commit }, isAvailable) {
      commit('SET_ADJUST_WATCHER_STATE', isAvailable)
    },
    ChangeAutoSaveState({ commit }, isAvailable) {
      commit('SET_AUTO_SAVE_STATE', isAvailable)
    },

    ChangeShowCostState({ commit, state }) {
      commit('SET_SHOW_COST_STATE', !state.showCost)
    },
    ChangeShowRateState({ commit, state }) {
      commit('SET_SHOW_RATE_STATE', !state.showRate)
    },

    UpdateGlobalStatic({ commit }, allSkuList = []) {
      const formatedList = allSkuList.map(item => {
        return {
          ...item,
          id: item.skuId
        }
      }).filter(item => (item.id && item.id != 0))
      skuStaticFunction(formatedList, (data) => {
        commit('SET_GLOBAL_SKU_STATISTICS', data)
      })
    },

    /**
     * 硬件设备相关
     */
    UpdateGlobalHardSkuList({ commit }, globalHardSkuList = []) {

      const globalHardSkuTool = reduceStatistics(globalHardSkuList)
      const globalHardSkuTotalPrice = globalHardSkuTool('price')({ mulByNum: true })
      const globalHardSkuTotalCost = globalHardSkuTool('cost')({ mulByNum: true })

      commit('SET_GLOBAL_HARD_SKU_LIST', globalHardSkuList)
      commit('SET_GLOBAL_HARD_SKU_TOTAL_PRICE', globalHardSkuTotalPrice)
      commit('SET_GLOBAL_HARD_SKU_TOTAL_COST', globalHardSkuTotalCost)
    },
    UpdateHardLockState({ commit }, { changeList, newState }) {
      changeList.map(sku => {
        commit('UPDATE_HARD_LOCK_STATES_AND_PRICE', { ...sku, priceLock: newState })
      })
    },
    UpdateHardLockPrice({ commit }, sku) {
      commit('UPDATE_HARD_LOCK_STATES_AND_PRICE', sku)
    },

    /**
     * 软件产品相关
     */
    UpdateGlobalSoftSkuList({ commit }, globalSoftSkuList) {
      commit('SET_GLOBAL_SOFT_SKU_LIST', globalSoftSkuList)
    },
    UpdateSoftLockState({ commit }, { changeList, newState }) {
      changeList.map(sku => {
        commit('UPDATE_SOFT_LOCK_STATES_AND_PRICE', { ...sku, priceLock: newState })
      })
    },
    UpdateSoftLockPrice({ commit }, sku) {
      commit('UPDATE_SOFT_LOCK_STATES_AND_PRICE', sku)
    },


    /**
     * 软件程序相关
     */
    UpdateGlobalSoftWorkList({ commit }, globalWorkList) {
      commit('SET_GLOBAL_SOFT_WORK_LIST', globalWorkList)
    },
    UpdateSoftProductLockState({ commit }, { changeList, newState }) {
      changeList.map(work => {
        commit('UPDATE_SOFT_PRODUCT_LOCK_STATES_AND_PRICE', { ...work, priceLock: newState })
      })
    },
    UpdateSoftProductLockPrice({ commit }, work) {
      commit('UPDATE_SOFT_PRODUCT_LOCK_STATES_AND_PRICE', work)
    },

    /**
     * 数字影片相关
     */
    UpdateGlobalFilmWorkList({ commit }, globalWorkList) {
      commit('SET_GLOBAL_FILM_WORK_LIST', globalWorkList)
    },
    UpdateDigitalFilmLockState({ commit }, { changeList, newState }) {
      changeList.map(work => {
        commit('UPDATE_DIGITAL_FILM_LOCK_STATES_AND_PRICE', { ...work, priceLock: newState })
      })
    },
    UpdateDigitalFilmLockPrice({ commit }, work) {
      commit('UPDATE_DIGITAL_FILM_LOCK_STATES_AND_PRICE', work)
    },


    // 品牌替换锁定
    UpdatebrandLockState({ commit }, { oldSku, newSku }) {
      if (oldSku.multimediaCategoryId == 1) {
        commit('REPLACE_HARD_SKU', { oldSku, newSku })
      } else if (oldSku.multimediaCategoryId == 2) {
        commit('REPLACE_SOFT_SKU', { oldSku, newSku })
      } else {
        commit('REPLACE_FILM_SKU', { oldSku, newSku })
      }
    }
  }
}

export default calculator