import request from "@/utils/request";

export function wxGetUserInfo(code) {
  return request({
    url: `/wx/work/user/get/${code}`,
    method: "post",
  });
}

export function wxLogin(code) {
  return request({
    url: `/login/wx/work`,
    method: "post",
    params: {
      code
    }
  });
}