(function (doc, win) {
    var docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      /*设置一个屏幕最大宽度临界值*/
      if(clientWidth >= 1680){
          docEl.style.fontSize = '14px';
      } else if(clientWidth < 1024) {
          docEl.style.fontSize = '12px';
      }
      else{
          docEl.style.fontSize = 14* (clientWidth / 1680) + 'px';
      }
    };
 
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
  })(document, window);