import request from "../../utils/request";

// 空间装饰工程分类
export function decorationClassification() {
  return request({
    url: "/standardization/decoration/project/classification",
    method: "get",
  });
}

// 查看装饰工程列表
export function decorationProjectAll(params) {
  return request({
    url: "/standardization/decoration/project/list",
    method: "get",
    params,
  });
}

// 装饰工程输入搜索
export function decorationQuery(data) {
  return request({
    url: "/standardization/decoration/project/input/query",
    method: "post",
    data,
  })
}

// 展项关联单品
export function getSkuByDisplayId(displayId) {
  return request({
    url: "/system/sku/list/" + displayId,
    method: "get",
  });
}

// 查看所有一级分类列表
export function productClassificationList() {
  return request({
    url: "/product/classification/list",
    method: "get",
  });
}

// 查看不同分类下一级分类列表
export function productClassificationSubList(state) {
  return request({
    url: "/product/classification/subclass/" + state,
    method: "get",
  });
}

// 查看一级分类下所有产品
export function productListByClassification(classifyId) {
  return request({
    url: "/standardization/product/list/" + classifyId,
    method: "get",
  });
}

// 查看一级分类下单品
export function skuListByProduct(params) {
  return request({
    url: "/standardization/sku/keyword",
    method: "get",
    params,
  });
}

// 查看产品的筛选条件
export function productFilterItem(id) {
  return request({
    url: "/standardization/product/condition/" + id,
    method: "get",
  });
}

// 单品筛选
export function skuFilter(data, params) {
  return request({
    url: "/standardization/sku/multi/screen",
    method: "post",
    data,
    params
  });
}

//查询所有承包方式
export function contractingSystem(classification) {
  return request({
    url: "/standardization/decoration/project/contract/" + classification,
    method: "get",
  });
}

//根据分类查询位置
export function queryLocation(classification) {
  return request({
    url: "/standardization/decoration/project/position/" + classification,
    method: "get",
  });
}

// 单品关联查询
export function skuAssociation(params) {
  return request({
    url: '/rule/sku/association',
    method: 'get',
    params
  })
}

// 关联单品计算
export function getSkuRelatedItem(data) {
  return request({
    url: '/sku/relation/related/item',
    method: 'post',
    params: data
  })
}

// 集成智能化分类查询
export function intelligentClassifications() {
  return request({
    url: '/intelligent/Classification/list',
    method: 'get'
  })
}

// 单品输入联合查询
export function skuInputSearch(data) {
  return request({
    url: '/standardization/sku/query/sku',
    method: 'post',
    data,
    headers: {
      repeatSubmit: false
    }
  })
}


// 单品输入联合查询
export function skuStatistics(data) {
  return request({
    url: '/standardization/sku/count',
    method: 'post',
    data,
    headers: {
      repeatSubmit: false
    }
  })
}

// 工作内容列表查询
export function workAll(id) {
  return request({
    url: '/estimate/soft/specification/detail/' + id,
    method: 'get'
  })
}

// 工作内容关联列表查询
export function workConfigAll(id) {
  return request({
    url: '/estimate/soft/product/config/door/' + id,
    method: 'get'
  })
}



// 工作内容列表加详情按需查询
export function queryWorkMessage(ids) {
  return request({
    url: '/estimate/soft/specification/options/' + ids,
    method: 'get'
  })
}

// 工作内容输入搜索
export function workNameSearch(params) {
  return request({
    url: '/estimate/soft/specification/blurry',
    method: 'get',
    params
  })
}


// 软件产品输入查询
export function workSearchAll(params) {
  return request({
    url: '/estimate/soft/product/input',
    method: 'get',
    params
  })
}


// 品牌替换查询
export function skuReplaceQuery(params) {
  return request({
    url: '/standardization/sku/replace/brand',
    method: 'get',
    params
  })
}



// 招标代理费计算
export function tenderCalculator(params) {
  return request({
    url: '/tender/price/calculator',
    method: 'get',
    params
  })
}


